import SplitType from "split-type";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

type DomElements = {
    [key: string]: any;
};

export class AnimateInHeadline {
    dom: DomElements = {};
    charsToAnimate = {};
    element: HTMLElement;
    complete: (() => void) | null;
    options: {
        tallType: boolean;
        trigger: string;
        delay: number;
        triggerElement: HTMLElement;
    };

    constructor(
        htmlElement: HTMLElement,
        options: {
            tallType?: boolean;
            trigger?: string;
            delay?: number;
            triggerElement?: HTMLElement;
        } = {}
    ) {
        this.element = htmlElement;
        this.options = {
            tallType: options.tallType !== undefined ? options.tallType : false,
            trigger: options.trigger || "inview",
            delay: options.delay || 0,
            triggerElement: options.triggerElement || this.element,
        };

        this.complete = null;

        this.prepareText();
    }

    prepareText() {
        this.dom.element = this.element;
        // Set up split type instance of chars
        this.dom.splitText = new SplitType(this.element, { types: "lines,chars" });

        // Get an array of all the characters
        const charsToAnimate = this.dom.splitText.chars!;

        // Set to invisible and low font-weight (height)
        gsap.set(charsToAnimate, { fontWeight: this.options.tallType ? 420 : 220, autoAlpha: 0 });
        this.charsToAnimate = charsToAnimate;

        let mm = gsap.matchMedia(),
            breakPoint = 1024;

        mm.add(
            {
                isDesktop: `(min-width: ${breakPoint}px)`,
                isMobile: `(max-width: ${breakPoint - 1}px)`,
            },
            () => {
                return () => {
                    // On changing breakpoint and text is still split (hasn't animated in yet), then revert
                    if (this.dom.splitText.isSplit) {
                        this.dom.splitText.revert();
                    }
                };
            }
        );

        if (this.options.trigger === "inview") {
            setTimeout(() => {
                ScrollTrigger.create({
                    trigger: this.options.triggerElement,
                    start: "top 80%",
                    once: true,
                    onEnter: () => {
                        this.play();
                    },
                });
            }, 1);
        }
    }

    onComplete(callback: () => void) {
        if (typeof callback === "function") {
            this.complete = callback;
        } else {
            throw new Error("onComplete callback must be a function");
        }
    }

    play() {
        // Animate in line by line
        gsap.timeline({
            delay: this.options.delay,
            onComplete: () => {
                this.dom.splitText.revert();

                if (this.complete) {
                    this.complete();
                }
            },
        })
            .to(this.charsToAnimate, {
                autoAlpha: 1,
                duration: 0,
                stagger: { each: 0.08 },
            })
            .to(
                this.charsToAnimate,
                {
                    duration: 0.6,
                    fontWeight: this.options.tallType ? 700 : 420,
                    stagger: { each: 0.08 },
                    ease: "power2.out",
                },
                0
            );
    }
}
