import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { getLenisInstance } from "../../scripts/index";
import SplitType from "split-type";
import { AnimateInHeadline } from "@helpers/animate-in-headline";
import { Carousel } from "@components/Carousel/carousel";
import Lottie from "lottie-web/build/player/lottie_light";
import CheckmarkAnimation from "./lotties/SR-checkmark.json";
import HeadlineMobileAnimation from "./lotties/mobile-headline.json";

gsap.registerPlugin(ScrollTrigger);

const moduleCampaign: HTMLElement = document.querySelector("[data-module-campaign]")!;
const campaignAnimationElement: HTMLElement = document.querySelector(
    "[data-module-campaign-anim]"
)!;

const campaignLottieCheckmark: HTMLElement = document.querySelector(
    "[data-module-campaign-lottie-checkmark]"
)!;

const campaignRotatingElement: HTMLElement = moduleCampaign.querySelector(
    "[data-module-campaign-rotate]"
)!;

const campaignMarquee: HTMLElement = moduleCampaign.querySelector(
    "[data-module-campaign-marquee]"
)!;

const campaignPoster: HTMLElement = moduleCampaign.querySelector("[data-module-campaign-poster]")!;
const campaignHeadline: HTMLElement = moduleCampaign.querySelector("[data-animate-in-headline]")!;
const campaignHeadlineSpotifyBtn: HTMLAnchorElement =
    moduleCampaign.querySelector("[data-headline-btn]")!;
let headlineAnimation: any;

headlineAnimation = new AnimateInHeadline(campaignHeadline, {
    trigger: "manual",
});
headlineAnimation.onComplete(() => {
    // Checkmark lottie
    Lottie.loadAnimation({
        loop: false,
        container: campaignLottieCheckmark,
        animationData: CheckmarkAnimation,
    });

    gsap.to(campaignHeadlineSpotifyBtn, {
        autoAlpha: 1,
        scale: 1,
        duration: 0.7,
        delay: 1,
        ease: "elastic.out(0.6, 0.3)",
    });
});

// Checkmark lottie animation playing on enter section and text is animated in
if (campaignLottieCheckmark) {
    ScrollTrigger.create({
        trigger: moduleCampaign,
        start: "top 30%",
        once: true,
        onEnter() {
            // Desktop only
            headlineAnimation.play();

            // Mobile only
            const headlineMobileLottieContainer = moduleCampaign.querySelector(
                "[data-module-campaign-headline-lottie]"
            )!;

            Lottie.loadAnimation({
                loop: false,
                container: headlineMobileLottieContainer,
                animationData: HeadlineMobileAnimation,
            });
        },
    });
}

// Rotating animation
const rotateAnimTL = gsap
    .timeline({ repeat: -1 })
    .to(
        campaignRotatingElement,
        {
            rotation: 360,
            transformOrigin: "center",
            duration: 40,
            ease: "none",
        },
        0
    )
    .to(
        campaignMarquee,
        {
            xPercent: -50,
            duration: 40,
            ease: "none",
        },
        0
    );

// Update animations on scroll
getLenisInstance().on("scroll", (event: any) => {
    let { velocity, direction } = event;
    let timeScale = Math.abs(velocity);

    // Reverse direction if scrolling up
    if (direction == -1) {
        rotateAnimTL.timeScale(-Math.abs(timeScale + 1));
    } else {
        rotateAnimTL.timeScale(timeScale + 1);
    }
});

// Split poster text into lines, to be animated in + styled pr. line
// let splitText;
if (campaignPoster) {
    document.fonts.ready.then(() => {
        new SplitType(campaignPoster, { types: "lines" });
        // gsap.set(splitText.chars, { autoAlpha: 0 });
    });
}

// Carousel init
const sliderElement = moduleCampaign.querySelector<HTMLElement>(".keen-slider");
const paginationElement = moduleCampaign.querySelector<HTMLElement>(".pagination");

if (sliderElement && paginationElement) {
    new Carousel(sliderElement, paginationElement);
}
