// /**
//  * inspiration from https://github.com/paulirish/lite-youtube-embed?tab=readme-ov-file
//  */

type DomElements = {
    [key: string]: Element | null;
};

declare global {
    interface Window {
        YT: any;
    }
}

export class EmbedYoutube {
    preconnected = false;
    videoId = null as string | null | number;
    isPlaying = false;
    player = undefined;

    dom: DomElements = {
        placeholderElement: null,
    };

    /**
     *
     * @param {Element} domReference - The element to work from.
     */
    constructor(placeholderElement: Element | null) {
        this.dom.placeholderElement = placeholderElement;

        if (this.dom.placeholderElement) {
            this.videoId = this.dom.placeholderElement.getAttribute("data-videoid");
        }

        this.warmConnections();
    }

    // Chrome & Edge desktop have no problem with the basic YouTube Embed with ?autoplay=1
    // However Safari desktop and most/all mobile browsers do not successfully track the user gesture of clicking through the creation/loading of the iframe,
    // so they don't autoplay automatically. Instead we must load an additional 2 sequential JS files (1KB + 165KB) (un-br) for the YT Player API
    needsYTApiForAutoplay =
        navigator.vendor.includes("Apple") || navigator.userAgent.includes("Mobi");

    // /**
    //  * Begin pre-connecting to warm up the iframe load
    //  * Since the embed's network requests load within its iframe,
    //  *   preload/prefetch'ing them outside the iframe will only cause double-downloads.
    //  * So, the best we can do is warm up a few connections to origins that are in the critical path.
    //  *
    //  * Maybe `<link rel=preload as=document>` would work, but it's unsupported: http://crbug.com/593267
    //  * But TBH, I don't think it'll happen soon with Site Isolation and split caches adding serious complexity.
    //  */
    warmConnections() {
        if (this.preconnected) return;

        // The iframe document and most of its subresources come right off youtube.com
        this.addPrefetch("preconnect", "https://www.youtube-nocookie.com");
        // The botguard script is fetched off from google.com
        this.addPrefetch("preconnect", "https://www.google.com");

        // Not certain if these ad related domains are in the critical path. Could verify with domain-specific throttling.
        this.addPrefetch("preconnect", "https://googleads.g.doubleclick.net");
        this.addPrefetch("preconnect", "https://static.doubleclick.net");

        this.preconnected = true;
    }

    /**
     * Add a <link rel={preload | preconnect} ...> to the head
     */
    addPrefetch(kind: string, url: string, as?: string) {
        const linkEl = document.createElement("link");
        linkEl.rel = kind;
        linkEl.href = url;
        if (as) {
            linkEl.as = as;
        }
        document.head.append(linkEl);
    }

    fetchYTPlayerApi() {
        if (window.YT || (window.YT && window.YT.Player)) return;

        return new Promise((res, rej) => {
            const el = document.createElement("script");
            el.src = "https://www.youtube.com/iframe_api";
            el.async = true;
            el.onload = (_) => {
                window.YT.ready(res);
            };
            el.onerror = rej;

            const firstScriptTag = document.getElementsByTagName("script")[0];
            if (firstScriptTag) {
                firstScriptTag.parentNode?.insertBefore(el, firstScriptTag);
            }
        });
    }

    async addYTPlayerIframe(params: any[] | URLSearchParams) {
        await this.fetchYTPlayerApi();

        const videoPlaceholderEl = document.createElement("div");
        this.dom.placeholderElement?.append(videoPlaceholderEl);

        const paramsObj = Object.fromEntries(params.entries());

        this.player = new window.YT.Player(videoPlaceholderEl, {
            width: "100%",
            height: "100%",
            videoId: this.videoId,
            playerVars: paramsObj,
            events: {
                onReady: (event: any) => {
                    event.target.playVideo();
                    this.isPlaying = true;
                },
                onStateChange: (event: any) => {
                    if (event.data === -1 || event.data === 2 || event.data === 0) {
                        this.isPlaying = false;
                    }

                    if (event.data === 1 || event.data === 3) {
                        this.isPlaying = true;
                    }
                },
            },
        });
    }

    async addIframe() {
        if (this.dom.placeholderElement?.classList.contains("lyt-activated")) {
            //@ts-ignore-next-line
            this.player?.playVideo().seekTo(0);
            return;
        }

        this.dom.placeholderElement?.classList.add("lyt-activated");

        const params = new URLSearchParams(
            this.dom.placeholderElement?.getAttribute("params") || []
        );

        params.append("autoplay", "1");
        params.append("playsinline", "1");

        return this.addYTPlayerIframe(params);
    }

    stopVideo() {
        //@ts-ignore-next-line
        this.player?.pauseVideo();
    }

    initialize() {
        this.addIframe();
    }
}
