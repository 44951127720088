import { AnimateInHeadline } from "@helpers/animate-in-headline";
import SplitType from "split-type";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function calculateScalingFactor(element: HTMLElement): number {
    // Get the viewport dimensions
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Get element dimensions
    const elementWidth = element.offsetWidth;
    const elementHeight = element.offsetHeight;

    // Calculate scaling factors for width and height
    const widthScale = viewportWidth / elementWidth;
    const heightScale = viewportHeight / elementHeight;

    // Choose the larger scaling factor to fill out the largest part of the screen
    let scalingFactor = Math.max(widthScale, heightScale);

    // Account for rounded borders (only a problem in landscape)
    if (viewportWidth > viewportHeight) {
        const ratio = viewportHeight / viewportWidth;
        const extraScale = 1 - ratio;
        scalingFactor += extraScale;
    } else {
        // Only add a tiny bit to avoid flicker when resizing
        scalingFactor += 0.15;
    }

    return scalingFactor;
}

// Get elements
const missionElement = document.querySelector("[data-module-mission]")!;
const missionHeadline: HTMLElement | null = missionElement.querySelector(
    "[data-module-mission-headline]"
)!;
const expandAnimElement = missionElement.querySelector("[data-module-mission-pill]")!;
const expandAnimElementSvg = expandAnimElement.querySelector("svg")!;
const expandAnimElementToScale: HTMLElement | null = expandAnimElement.querySelector(
    "[data-module-mission-pill-bg]"
)!;
const missionSecondHeadline: HTMLElement = missionElement.querySelector(
    "[data-animate-in-headline]"
)!;

// Prepare headline to be animated in letter by letter
document.fonts.ready.then(() => {
    missionHeadlineAnimation(missionHeadline);
});

// Second headline animate in
new AnimateInHeadline(missionSecondHeadline);

function missionHeadlineAnimation(text: HTMLElement) {
    const splitText = new SplitType(text, { types: "lines,chars,words" });
    gsap.set(splitText.chars, { fontWeight: 220, autoAlpha: 0 });
    let linesCompleted = 0;
    const words = splitText.words!;

    const charOfLastWord = words[words.length - 1].querySelectorAll(".char");

    ScrollTrigger.batch(splitText.lines, {
        once: true,
        start: "center bottom",
        end: "bottom bottom",
        onEnter(elements) {
            elements.forEach((line) => {
                const linesTotal: number = splitText.lines?.length || 0;
                const chars = line.querySelectorAll(".char");

                const makeMusic = gsap
                    .timeline({
                        // repeatRefresh: true,
                        // repeat: -1,
                        paused: true,
                        onComplete() {
                            // gsap.to(chars, {
                            //     duration: 0.6,
                            //     fontWeight: 420,
                            //     // stagger: { each: 0.09 },
                            //     ease: "power2.out",
                            // });
                            // splitText.revert();
                        },
                    })
                    // .to(charOfLastWord, {
                    //     duration: 0.5,
                    //     stagger: { each: 0.03 },
                    //     fontWeight: () => gsap.utils.random(100, 420),
                    //     ease: "power1.inOut",
                    // });
                    .to(charOfLastWord, {
                        duration: 0.7,
                        fontWeight: () => 100,
                        stagger: { amount: 0.7, repeat: -1, yoyo: true },
                        ease: "power1.inOut",
                    });

                gsap.timeline({
                    onComplete() {
                        linesCompleted++;

                        if (linesCompleted === linesTotal) {
                            makeMusic.play();
                        }
                    },
                })
                    .to(chars, {
                        autoAlpha: 1,
                        duration: 0,
                        stagger: { each: 0.08 },
                    })
                    .to(
                        chars,
                        {
                            duration: 0.6,
                            fontWeight: 420,
                            stagger: { each: 0.08 },
                            ease: "power2.out",
                        },
                        0
                    );
            });
        },
    });
}

// Setup timeline with scrollTrigger for expand animation
gsap.timeline({
    scrollTrigger: {
        trigger: expandAnimElement,
        start: "center center",
        end: "bottom center",
        pin: true,
        scrub: true,
        invalidateOnRefresh: true,
        onLeaveBack() {
            gsap.set(expandAnimElementToScale, { clearProps: "scale" });
        },
    },
})
    .to(expandAnimElementSvg, {
        autoAlpha: 0,
        duration: 0.15,
    })
    .to(
        expandAnimElementToScale,
        {
            scale: () => calculateScalingFactor(expandAnimElementToScale),
            ease: "none",
        },
        0
    );
