import tailwindConfig from "../../../tailwind.config.mjs";
import { gsap } from "gsap";
import Lottie from "lottie-web/build/player/lottie_light";
import { flatten } from "lottie-colorify";
import GlobesAnimation from "./lotties/globes.json";

import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const frameElement = document.querySelector("[data-frame]")!;
const logoElement = frameElement.querySelector("[data-frame-logo]");
const intersectingSections = document.querySelectorAll("[data-intersect]");
const COLOR_VARS = tailwindConfig.theme.extend.colors;

// Lottie animation
const globesAnimContainer = frameElement.querySelector("[data-frame-lottie-globes]")!;
let globesAnim: any;

// Make sure other scrolltriggers are created before this
setTimeout(() => {
    let mm = gsap.matchMedia(),
        breakPoint = 1024;
    mm.add(
        {
            isDesktop: `(min-width: ${breakPoint}px)`,
            isMobile: `(max-width: ${breakPoint - 1}px)`,
        },
        (context) => {
            const { isDesktop } = context.conditions!;

            intersectingSections.forEach((section) => {
                ScrollTrigger.create({
                    trigger: section,
                    start: () => (isDesktop ? "top center" : "top 40px"),
                    end: () => (isDesktop ? "bottom center" : "bottom 40px"),
                    invalidateOnRefresh: true,
                    onToggle: (self) => {
                        if (self.isActive) {
                            const activeSectionColor =
                                self.trigger?.getAttribute("data-intersect")!;
                            const activeSectionSimplify = Boolean(
                                self.trigger?.getAttribute("data-intersect-simplify")
                            );
                            const root = document.querySelector<HTMLElement>(":root");
                            const getColor =
                                COLOR_VARS[activeSectionColor as keyof typeof COLOR_VARS];

                            frameElement.classList.toggle("simplify", activeSectionSimplify);

                            setTimeout(() => {
                                // Globes animation
                                let globesBycurrentFrame = 0;
                                if (globesAnim) {
                                    globesBycurrentFrame = globesAnim.currentFrame;
                                    globesAnim.destroy();
                                }

                                globesAnim = Lottie.loadAnimation({
                                    container: globesAnimContainer,
                                    autoplay: false,
                                    animationData: flatten(getColor, GlobesAnimation),
                                });

                                globesAnim.goToAndPlay(globesBycurrentFrame, true);
                            }, 100);

                            if (root != null) {
                                root.style.setProperty("--frame-color", getColor);
                            }
                        }
                    },
                });
            });

            // Logo: shrink on scroll - desktop only!
            if (isDesktop && logoElement) {
                gsap.to(logoElement, {
                    scale: 0.8,
                    ease: "none",
                    transformOrigin: "top left",
                    scrollTrigger: { scrub: true, end: 200 },
                });
            }
        }
    );
}, 0);
