/** @type {import('tailwindcss').Config} */

function pxToVw(px, layout) {
    return ((px / layout) * 100).toFixed(3);
}

export default {
    content: ["./src/**/*.{astro,html,js,jsx,md,mdx,svelte,ts,tsx,vue}"],
    corePlugins: {
        container: false,
    },
    theme: {
        fontFamily: {
            headline: ["SoundsVar", "Arial Narrow", "sans-serif"],
            body: ["Instrument Sans", "sans-serif"],
            mono: ["Space Mono", "monospace", "sans-serif"],
        },
        extend: {
            gap: {
                sm: `${pxToVw(8, 375)}vw`,
                lg: `${pxToVw(24, 1728)}vw`,
            },
            screens: {
                "2xl": "1512px",
                "3xl": "1800px",
                "sm-landscape-only": { raw: "(orientation: landscape) and (max-width: 767px)" },
                "has-hover": { raw: "(hover: hover)" },
            },
            colors: {
                primary: "#27281A",
                highlight: "#CCFF00",
                green: "#033D34",
                light: "#FAF9F1",
                gray: "#E5E4DC",
                grayDark: "#A9A9A3",
            },
            borderColor: {
                DEFAULT: "rgb(39 40 26 / 0.2)",
                neg: "rgb(250 249 241 / 0.2)",
            },
            transitionTimingFunction: {
                "out-cubic": "cubic-bezier(0.33,1,0.68,1)",
            },

            zIndex: {
                frame: 11,
                menu: 30,
                modal: 40,
                video: 40,
                loader: 50,
            },
        },
    },
    plugins: [
        function ({ addComponents, addUtilities, theme }) {
            addComponents({
                ".container": {
                    maxWidth: "343px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    "@screen md": {
                        maxWidth: "720px",
                    },
                    "@screen lg": {
                        maxWidth: `calc(100vw - (${pxToVw(24, 1728)}vw * 2))`,
                    },
                },
                ".text-sideways": {
                    textOrientation: "sideways-right",
                    writingMode: "vertical-rl",
                    transform: "rotate(180deg)",
                },
                ".split-text": {
                    fontKerning: "none",
                },
                ".text-outline": {
                    "-webkit-text-stroke": `0.008em ${theme("colors.green")}`,
                    "-webkit-text-fill-color": "transparent",
                },
                ".ignore-br-sm br": {
                    display: "none",
                    "@screen md": {
                        display: "block",
                    },
                },
            });
            addUtilities({
                ".ts-body": {
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: 500,
                    fontFamily: theme("fontFamily.body"),
                    "@screen lg": {
                        fontSize: "clamp(14px, -0.169rem + 1.09vw, 1.6875rem)",
                        lineHeight: "150%",
                    },
                },
                ".ts-menu-link": {
                    fontSize: "8.1rem", // 130px
                    lineHeight: "60%",
                    fontWeight: 420,
                    textTransform: "uppercase",
                    fontFamily: theme("fontFamily.headline"),
                    "@screen sm-landscape-only": {
                        fontSize: "3.6rem",
                    },
                    "@screen lg": {
                        fontSize: "10rem", // 160px in 1728
                    },
                },
                ".ts-headline-hero": {
                    fontSize: "8.375rem", //134px in Figma, smaller here
                    lineHeight: "63%",
                    fontWeight: 420,
                    textTransform: "uppercase",
                    fontFamily: theme("fontFamily.headline"),
                    "@screen lg": {
                        fontSize: "12.73vw", // 270px in 1728
                    },
                },
                ".ts-headline-hero-long": {
                    fontSize: "7.5rem", // Changed on mobile to accomodate a looonger text
                    lineHeight: "63%",
                    fontWeight: 420,
                    textTransform: "uppercase",
                    fontFamily: theme("fontFamily.headline"),
                    "@screen lg": {
                        fontSize: "12.73vw", // 270px in 1728
                    },
                },
                ".ts-subtitle-hero": {
                    fontSize: "0.875rem", //14px
                    lineHeight: "105%", //14.7px
                    fontWeight: 700,
                    letterSpacing: "0.03em",
                    textTransform: "uppercase",
                    fontFamily: theme("fontFamily.mono"),
                    "@screen lg": {
                        lineHeight: "112%",
                        fontSize: "1rem", //16.8px
                    },
                },
                ".ts-headline-mission": {
                    fontSize: "9rem", // 144px
                    lineHeight: "63%",
                    fontWeight: 420,
                    textTransform: "uppercase",
                    fontFamily: theme("fontFamily.headline"),
                    "@screen lg": {
                        fontSize: "20vw",
                    },
                },
                ".ts-headline-mission-sm": {
                    fontSize: "9rem", // 144px
                    lineHeight: "63%",
                    fontWeight: 420,
                    textTransform: "uppercase",
                    fontFamily: theme("fontFamily.headline"),
                    "@screen lg": {
                        fontSize: "15.62vw",
                    },
                },
                ".ts-headline-works-player": {
                    fontSize: "9rem", // 144px
                    lineHeight: "63%",
                    fontWeight: 500,
                    textTransform: "uppercase",
                    fontFamily: theme("fontFamily.headline"),
                    "@screen lg": {
                        fontSize: "15.6vw", // 270px in 1728
                    },
                },
                ".ts-headline-works": {
                    fontSize: "4.75rem", // 76px
                    lineHeight: "63%",
                    fontWeight: 420,
                    textTransform: "uppercase",
                    fontFamily: theme("fontFamily.headline"),
                    "@screen lg": {
                        fontSize: "4.62vw", // 80px in 1728
                    },
                },
                ".ts-headline-works-open": {
                    fontSize: "8rem", // 144px originally, but made a bit smaller
                    lineHeight: "63%",
                    fontWeight: 420,
                    textTransform: "uppercase",
                    fontFamily: theme("fontFamily.headline"),
                    "@screen lg": {
                        fontSize: "8.2vw",
                    },
                    "@screen 2xl": {
                        fontSize: "7.2vw",
                    },
                },
                ".ts-text-works": {
                    fontSize: "17px",
                    lineHeight: "150%",
                    fontWeight: 500,
                    fontFamily: theme("fontFamily.body"),
                    "@screen lg": {
                        fontSize: "clamp(16px, -0.169rem + 1.11vw, 1.5rem)",
                    },
                },
                ".ts-toptext-campaign": {
                    fontSize: "0.775rem",
                    lineHeight: "105%",
                    fontWeight: 700,
                    letterSpacing: "0.03em",
                    textTransform: "uppercase",
                    fontFamily: theme("fontFamily.mono"),
                    "@screen 2xl": {
                        fontSize: "0.875rem", // 14px in 1728
                    },
                },
                ".ts-headline-campaign": {
                    textTransform: "uppercase",
                    fontFamily: theme("fontFamily.headline"),
                    "@screen lg": {
                        fontSize: "18.51vw", // 320px in 1728
                        fontWeight: 420,
                        lineHeight: "63%",
                    },
                },
                ".ts-headline-2-campaign": {
                    fontSize: "9rem", // 144px
                    lineHeight: "63%",
                    fontWeight: 420,
                    textTransform: "uppercase",
                    fontFamily: theme("fontFamily.headline"),
                    "@screen lg": {
                        fontSize: "14.46vw", // 480px in 1728
                    },
                },
                ".ts-headline-3-campaign": {
                    fontSize: "5.6rem", // 90px
                    lineHeight: "63%",
                    fontWeight: 420,
                    textTransform: "uppercase",
                    fontFamily: theme("fontFamily.headline"),
                    "@screen lg": {
                        fontSize: "9.25vw", // 160px in 1728
                    },
                },
                ".ts-poster-campaign": {
                    // fontSize: "3.6rem", // 58px original
                    fontSize: "3rem", // 48px, fixed to fit
                    lineHeight: "81%",
                    fontWeight: 600,
                    textTransform: "uppercase",
                    fontFamily: theme("fontFamily.headline"),
                    "@screen md": {
                        fontSize: "6.5rem",
                    },
                    "@screen lg": {
                        fontSize: "9.83vw", // 170px in 1728
                    },
                },
                ".ts-poster-campaign-feat": {
                    fontSize: "5rem",
                    lineHeight: "81%",
                    fontWeight: 600,
                    textTransform: "uppercase",
                    fontFamily: theme("fontFamily.headline"),
                    "@screen md": {
                        fontSize: "8.5rem",
                    },
                    "@screen lg": {
                        fontSize: "12.73vw", // 270px in 1728
                    },
                },
                ".ts-label-campaign": {
                    fontSize: "0.875rem", // 14px
                    lineHeight: "105%",
                    fontWeight: 700,
                    letterSpacing: "0.03em",
                    textTransform: "uppercase",
                    fontFamily: theme("fontFamily.mono"),
                    "@screen xl": {
                        fontSize: "1rem", // 16px in 1728
                    },
                },
                ".ts-headline-takeaction": {
                    fontSize: "8rem", // 144px in Figma, but made a bit smaller
                    lineHeight: "63%",
                    fontWeight: 420,
                    textTransform: "uppercase",
                    fontFamily: theme("fontFamily.headline"),
                    "@screen lg": {
                        fontSize: "24vw", // 425px in 1728
                    },
                },
                ".ts-card-takeaction": {
                    fontSize: "4.75rem", // 76px
                    lineHeight: "63%",
                    fontWeight: 420,
                    textTransform: "uppercase",
                    fontFamily: theme("fontFamily.headline"),
                    "@screen lg": {
                        fontSize: "4.86vw", // 84px in 1728
                    },
                },
                ".ts-headline-quote": {
                    fontSize: "4.5rem", // 76px in Figma, but made a bit smaller
                    lineHeight: "55%",
                    fontWeight: 300,
                    textTransform: "uppercase",
                    fontFamily: theme("fontFamily.headline"),
                    "@screen lg": {
                        fontSize: "4.86vw", // 84px in 1728
                    },
                },
                ".ts-quotee-quote": {
                    fontSize: "0.875rem", // 14px
                    lineHeight: "105%",
                    fontWeight: 700,
                    textTransform: "uppercase",
                    fontFamily: theme("fontFamily.mono"),
                    "@screen xl": {
                        fontSize: "1rem", // 16px in 1728
                    },
                },
                ".ts-headline-contact": {
                    fontSize: "4.75rem", // 76px
                    lineHeight: "63%",
                    fontWeight: 420,
                    textTransform: "uppercase",
                    fontFamily: theme("fontFamily.headline"),
                    "@screen lg": {
                        fontSize: "9.25vw", // 160px in 1728
                    },
                },
                // Used on how it works and partners
                ".ts-label-generic": {
                    fontSize: "0.875rem", // 14px
                    lineHeight: "105%",
                    fontWeight: 700,
                    textTransform: "uppercase",
                    fontFamily: theme("fontFamily.mono"),
                    "@screen lg": {
                        fontSize: "1rem", // 16px in 1728
                    },
                    "@screen 2xl": {
                        fontSize: "0.92vw", // 16px in 1728
                    },
                },
                // Used on how it works and partners
                ".ts-headline-generic": {
                    fontSize: "9rem", // 144px
                    lineHeight: "63%",
                    fontWeight: 420,
                    textTransform: "uppercase",
                    fontFamily: theme("fontFamily.headline"),
                    "@screen lg": {
                        fontSize: "24.59vw", // 425px in 1728
                    },
                },
                ".ts-headline-collabs": {
                    fontSize: "4.75rem", // 76px
                    lineHeight: "63%",
                    fontWeight: 420,
                    textTransform: "uppercase",
                    fontFamily: theme("fontFamily.headline"),
                    "@screen lg": {
                        fontSize: "4.86vw", // 84px in 1728
                    },
                },
                ".ts-footer-headline": {
                    fontSize: "14px",
                    lineHeight: "16px",
                    fontFamily: theme("fontFamily.body"),
                    textTransform: "uppercase",
                    fontWeight: 700,
                    "@screen lg": {
                        fontSize: "clamp(12px, 0.24rem + 0.6vw, 1.625rem)",
                        lineHeight: "114%",
                    },
                },
                ".ts-footer-list": {
                    fontSize: "24px",
                    lineHeight: "133%",
                    fontFamily: theme("fontFamily.body"),
                    fontWeight: 500,
                    "@screen lg": {
                        fontSize: "clamp(1.125rem, 0.3rem + 1.115vw, 2.5625rem)",
                    },
                },
                ".ts-loader": {
                    fontSize: "0.875rem", //14px
                    lineHeight: "105%", //14.7px
                    fontWeight: 700,
                    letterSpacing: "0.03em",
                    textTransform: "uppercase",
                    fontFamily: theme("fontFamily.mono"),
                    "@screen 3xl": {
                        fontSize: "0.81vw",
                    },
                },
                ".ts-headline-wysiwyg": {
                    fontSize: "4rem",
                    lineHeight: "63%",
                    fontWeight: 420,
                    textTransform: "uppercase",
                    fontFamily: theme("fontFamily.headline"),
                },
            });
        },
    ],
};
