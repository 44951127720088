import { gsap } from "gsap";
import { toggleScrollLock } from "@helpers/scroll-lock";
import { Scramble } from "@helpers/scramble-in-text";

const MIN_MS_TO_SHOW = 2500;
const MAX_MS_TO_SHOW = 6000;

let elapsedMs: number = 0;
let timerInterval: NodeJS.Timeout | null = null;

// Get element(s)
const loaderElement = document.querySelector("[data-loader]")!;
const loaderLogo = loaderElement.querySelector("[data-loader-logo]")!;
const loaderText: HTMLElement = loaderElement.querySelector("[data-loader-text]")!;

// On load - create clippath on loader and lock scroll
gsap.set(loaderElement, { "clip-path": "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%" });

// Toggle scroll lock while loader is visible
toggleScrollLock();

// Start timer
startTimer();

// Animate in text
new Scramble(loaderText).play();

// Setup listener on hero video when page is loaded
document.addEventListener("heroVideoCanPlay", () => {
    const timeToWait = MIN_MS_TO_SHOW - elapsedMs;
    hideLoader(loaderElement, timeToWait);
});

// if nothing has happended after 6 seconds, hide the loader
setTimeout(() => {
    if (timerInterval) {
        hideLoader(loaderElement, 0);
    }
}, MAX_MS_TO_SHOW);

function startTimer(): void {
    timerInterval = setInterval(() => (elapsedMs += 10), 10);
}

function stopTimer(): void {
    if (timerInterval !== null) {
        clearInterval(timerInterval);
        timerInterval = null;
    }
}

function hideLoader(loaderElement: Element, timeout: number): void {
    stopTimer();

    // wait until we reach the minimum time
    setTimeout(() => {
        gsap.timeline()
            .to(loaderLogo, {
                opacity: 0,
                duration: 0.3,
                ease: "none",
            })
            .to(
                loaderText,
                {
                    opacity: 0,
                    duration: 0.3,
                    ease: "none",
                },
                0
            )
            .to(
                loaderElement,
                {
                    "clip-path": "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)",
                    duration: 1,
                    ease: "power3.inOut",
                    onStart() {
                        document.dispatchEvent(new CustomEvent("loaderHideStart"));
                    },
                    onUpdate() {
                        if (this.progress() > 0.5) {
                            document.dispatchEvent(new CustomEvent("loaderHideHalf"));
                        }
                    },
                    onComplete() {
                        toggleScrollLock();
                        loaderElement.remove();
                        document.dispatchEvent(new CustomEvent("loaderHideDone"));
                    },
                },
                0
            );
    }, timeout);
}
