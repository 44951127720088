import { AnimateInHeadline } from "@helpers/animate-in-headline";
import { Scramble } from "@helpers/scramble-in-text";

const moduleHeroElement = document.querySelector("[data-module-hero]")!;
const heroContentElements = moduleHeroElement.querySelectorAll("[data-module-hero-content]");
const HERO_SLIDE_INDEX_NAME = "heroVideoIndex";
let currentIndex: number;

// Function to get the previous first video index from local storage
function getPreviousFirstSlideIndex(): number {
    // Get the last video index from local storage
    const lastSlideIndex = localStorage.getItem(HERO_SLIDE_INDEX_NAME);

    // If there was no last video index, default to 0
    if (lastSlideIndex === null || isNaN(Number(lastSlideIndex))) {
        return 0;
    }

    // Parse the last video index as an integer and return it
    return parseInt(lastSlideIndex, 10);
}

// Function to set the last video index in local storage
function setLastSlideIndex(index: number): void {
    // Set the last video index in local storage
    localStorage.setItem(HERO_SLIDE_INDEX_NAME, index.toString());
}

// Function to getSlideIndex
function getFirstSlideIndex(videoCount: number): number {
    // Get the last video index
    const lastSlideIndex = getPreviousFirstSlideIndex();

    // Calculate the next video index
    const nextSlideIndex = (lastSlideIndex + 1) % videoCount;
    currentIndex = nextSlideIndex;

    // Set the last video index to the next video index
    setLastSlideIndex(nextSlideIndex);

    return nextSlideIndex;
}

function showSlide(element: Element): void {
    element.classList.replace("opacity-0", "opacity-100");
    element.classList.replace("absolute", "relative");
    element.classList.add("playing");
}

function hideSlide(element: Element) {
    element.classList.replace("opacity-100", "opacity-0");
    element.classList.replace("relative", "absolute");
    element.classList.remove("playing");
}

function animateInHeadline(instance: any): void {
    if (instance) {
        instance.play();
    }
}

function listenForVideoReady(video: HTMLVideoElement, callback: () => void) {
    const onVideoReady = () => {
        callback();
        video.removeEventListener("canplay", onVideoReady);
    };

    // Add eventlistener for the 'canplay' event
    video.addEventListener("canplay", onVideoReady);
}

// Get index of first slide to play
currentIndex = getFirstSlideIndex(heroContentElements.length);

// Find element from returned index and show
const activeSlideElement = heroContentElements[currentIndex];
showSlide(activeSlideElement);

// Split first headline and prepare for animate in.
let splitText: any;
let scramble: any;

// Wait for correct font is loaded before splitting text
document.fonts.ready.then(() => {
    splitText = new AnimateInHeadline(activeSlideElement.querySelector("h2")!, {
        trigger: "manual",
    });
});

// prepare subtext for animation
scramble = new Scramble(activeSlideElement.querySelector("p")!);

// Find video and listen for listenForVideoReady. When ready - emit even for loader to use
const video = activeSlideElement.querySelector("video");

// Listen for readystate in case "canplay" event is fired before we set it up (if video is cached fx)
if (video!.readyState > 3) {
    document.dispatchEvent(new CustomEvent("heroVideoCanPlay"));
} else {
    listenForVideoReady(video!, () => {
        document.dispatchEvent(new CustomEvent("heroVideoCanPlay"));
    });
}

// Seup event listeners:

// Play video when loader starts animating out
document.addEventListener("loaderHideStart", () => video!.play());

// Animate in first headline when loader is half way up
document.addEventListener(
    "loaderHideHalf",
    () => {
        animateInHeadline(splitText);
    },
    { once: true }
);

document.addEventListener(
    "loaderHideDone",
    () => {
        scramble.play(1.4);
    },
    { once: true }
);

function goToNextSlide() {
    // First, hide current slide
    const activeSlideElement = heroContentElements[currentIndex];
    const video = activeSlideElement.querySelector("video");
    hideSlide(activeSlideElement);
    video!.currentTime = 0;
    video!.pause();

    // Update currentindex
    currentIndex = currentIndex + 1 === heroContentElements.length ? 0 : currentIndex + 1;

    // Find next slide: show and play video
    const nextActiveSlideElement = heroContentElements[currentIndex];
    const nextVideo = nextActiveSlideElement.querySelector("video");

    setLastSlideIndex(currentIndex);
    showSlide(nextActiveSlideElement);
    nextVideo!.play();
}

heroContentElements.forEach((slide) => {
    const video = slide.querySelector("video")!;
    video.pause(); // video needs to have autoplay attribute to play on mobile. So we start by pausing all and only play the first one. (so the ended eventlistener will work)
    video.addEventListener("ended", () => {
        goToNextSlide();
    });
});

// Listen for shuffle button clicked + goes to next slide
document.querySelector("[data-play-next-slide]")?.addEventListener("click", () => {
    goToNextSlide();
});
