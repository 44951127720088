import { AnimateInHeadline } from "@helpers/animate-in-headline";
import { toggleScrollLock } from "@helpers/scroll-lock";
import { gsap } from "gsap";

const moduleHowItWorks = document.querySelectorAll("[data-module-how-it-works]");

let currentTL: gsap.core.Timeline;
let isOpen = false;

function toggleModal(timeLine: gsap.core.Timeline) {
    toggleScrollLock();
    timeLine.reversed(!timeLine.reversed());
    document.querySelector("[data-frame]")!.classList.toggle("z-50");
    document.querySelector("[data-frame]")!.classList.toggle("lg:z-frame");
    isOpen = !isOpen;
    if (isOpen) {
        currentTL = timeLine;
    }
}

moduleHowItWorks.forEach((moduleHowItWorksElement) => {
    // animate in headline
    const moduleHeadline: HTMLElement = moduleHowItWorksElement.querySelector(
        "[data-animate-in-headline]"
    )!;

    new AnimateInHeadline(moduleHeadline);

    // Items open and close
    const buttonElements = moduleHowItWorksElement.querySelectorAll("[data-button-activate]");
    const boxElement = moduleHowItWorksElement.querySelector("[data-box]");

    document.addEventListener("keydown", (event) => {
        const key = event.key;
        if (key === "Escape" && isOpen) {
            toggleModal(currentTL);
        }
    });

    buttonElements.forEach((button, buttonIndex) => {
        const modalElement = moduleHowItWorksElement.querySelector(`#modal-${buttonIndex}`);
        const closeButtonElement = modalElement?.querySelector("[data-button-close]");
        const modalBackgroundElement = modalElement?.querySelector("[data-modal-backdrop]");

        if (modalBackgroundElement) {
            const modalTl = gsap
                .timeline({ paused: true, onStart: () => {} })
                .to(boxElement, { opacity: 0, duration: 0.3 })
                .to(modalBackgroundElement, { autoAlpha: 0.2, duration: 0.3 }, "<")
                .to(modalElement, { autoAlpha: 1, duration: 0.3 }, "<")
                .reverse();

            modalBackgroundElement?.addEventListener("click", () => {
                toggleModal(modalTl);
            });

            button.addEventListener("click", () => {
                toggleModal(modalTl);
            });

            closeButtonElement?.addEventListener("click", () => {
                toggleModal(modalTl);
            });
        }
    });
});
