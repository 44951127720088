import SplitType from "split-type";
import { gsap } from "gsap";

type DomElements = {
    [key: string]: any;
};

export class Scramble {
    dom: DomElements = {};
    charsToAnimate = {};

    constructor(element: HTMLElement) {
        this.prepareText(element);
    }

    prepareText(text: HTMLElement) {
        // Set up split type instance of chars
        this.dom.splitText = new SplitType(text, { types: "lines,chars" });

        // Get an array of all the characters
        const chars = this.dom.splitText.chars!;

        // Calculate the number of characters to animate
        const numToAnimate = Math.min(15, Math.ceil(chars.length * 0.2)); // 20% of characters or max 15 chars

        // Randomly select characters to animate
        const charsToAnimate: Array<Object> = [];
        while (charsToAnimate.length < numToAnimate) {
            const randomIndex = Math.floor(Math.random() * chars.length);
            if (!charsToAnimate.includes(chars[randomIndex])) {
                charsToAnimate.push(chars[randomIndex]);
            }
        }

        // Set to invisible
        gsap.set(charsToAnimate, { autoAlpha: 0 });
        this.charsToAnimate = charsToAnimate;

        let mm = gsap.matchMedia(),
            breakPoint = 1024;

        mm.add(
            {
                isDesktop: `(min-width: ${breakPoint}px)`,
                isMobile: `(max-width: ${breakPoint - 1}px)`,
            },
            () => {
                return () => {
                    // On changing breakpoint and text is still split (hasn't animated in yet), then revert
                    if (this.dom.splitText.isSplit) {
                        this.dom.splitText.revert();
                    }
                };
            }
        );
    }

    play(delay: number = 0.5) {
        // Animate in the selected characters
        gsap.timeline().to(this.charsToAnimate, {
            delay: delay,
            autoAlpha: 1,
            duration: 0,
            stagger: {
                amount: 0.6,
            },
            onComplete: () => {
                this.dom.splitText.revert();
            },
        });
    }
}
