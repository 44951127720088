import { gsap } from "gsap";
import { EmbedYoutube } from "@helpers/youtube-embed";
import { toggleScrollLock } from "@helpers/scroll-lock";

const moduleVideoPlayerElements = document.querySelectorAll("[data-module-video-player]");
let modalIsOpen = false;

moduleVideoPlayerElements.forEach((moduleVideoPlayerElement) => {
    const toggleButtons = moduleVideoPlayerElement.querySelectorAll("[data-toggle-open-button]");

    const videoModalElement = moduleVideoPlayerElement.querySelector("[data-video-modal]");
    const placeholderElement = moduleVideoPlayerElement.querySelector(".youtube-embed");

    const videoModalBlur = document.querySelector("[data-video-modal-blur]");
    const videoModalContent = document.querySelector("[data-video-modal-content]");
    const videoModalClose = document.querySelector("[data-video-modal-close]");

    const YT = new EmbedYoutube(placeholderElement);

    gsap.set(videoModalContent, { y: -100 });

    const modalTl = gsap
        .timeline({ paused: true })
        .to(videoModalElement, { autoAlpha: 1, duration: 0.05 })
        .to(videoModalBlur, { opacity: 1, duration: 0.15 })
        .to(videoModalContent, { opacity: 1, y: 0, duration: 0.3 }, "<")
        .to(videoModalClose, { opacity: 1, y: 0, duration: 0.3 }, "<")
        .reverse();

    const toggleModal = (e: any) => {
        modalIsOpen = !modalIsOpen;

        if (modalIsOpen) {
            YT.initialize();
        } else {
            YT.stopVideo();
        }

        e.stopPropagation();
        modalTl.reversed(!modalTl.reversed());
        toggleScrollLock();

        // pause hero videos when opening modal
        const hero = document.querySelector("[data-module-hero]")!;
        const heroVideos = hero.querySelectorAll("video") || null;

        heroVideos.forEach((video: HTMLVideoElement) => {
            // find the video that is playing and pause when opening.
            if (modalIsOpen) {
                if (!video.paused) {
                    video.pause();
                    video.classList.add("paused");
                }
            } else if (video.classList.contains("paused")) {
                video.play();
                video.classList.remove("paused");
            }
        });
    };

    toggleButtons.forEach((btn: any) => {
        btn?.addEventListener("click", (e: any) => {
            if (
                // @ts-ignore
                CookieInformation.websiteUuid &&
                // @ts-ignore
                !CookieInformation.getConsentGivenFor("cookie_cat_marketing")
            ) {
                const cookiebanner = document.getElementById("coi-cookie");
                if (cookiebanner) cookiebanner.style.display = "block";
            } else {
                toggleModal(e);
            }
        });
    });

    videoModalBlur?.addEventListener("click", toggleModal);
});
