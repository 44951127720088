import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { getLenisInstance } from "../scripts/index";
gsap.registerPlugin(ScrollTrigger);

const getScrollbarWidth = () => window.innerWidth - document.documentElement.clientWidth;

/**
 * Get the current state of the scroll lock. `true` if the scroll lock is enabled, otherwise `false`.
 *
 * @type {boolean}
 */
export let currentState = false;

/**
 * Enable the scroll lock.
 */
export function enableScrollLock() {
    if (!currentState) {
        const bodyTag = document.body;
        const scrollBarWidth = getScrollbarWidth();
        bodyTag.style.marginRight = `${scrollBarWidth}px`;
        bodyTag.style.overflow = "hidden";

        ScrollTrigger.normalizeScroll(false);
        getLenisInstance().stop();

        // Remember state
        currentState = true;
    }
}

/**
 * @type {function}
 * @ignore
 */
export const enable = enableScrollLock;

/**
 * Disable the scroll lock
 */
export function disableScrollLock() {
    if (currentState) {
        const bodyTag = document.body;
        bodyTag.style.overflow = "";
        bodyTag.style.marginRight = "";

        ScrollTrigger.normalizeScroll({ type: "touch" });
        getLenisInstance().start();

        // Remember state
        currentState = false;
    }
}

/**
 * @type {function}
 * @ignore
 */
export const disable = disableScrollLock;

/**
 * Toggle the scroll lock between on and off
 */
export function toggleScrollLock() {
    if (currentState) {
        disableScrollLock();
    } else {
        enableScrollLock();
    }
}
