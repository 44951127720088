import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { toggleScrollLock } from "@helpers/scroll-lock";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const MENU_ACTIVE_CLASS = "menu-active";

const sections = document.querySelectorAll("[data-section-title]");
const menuBarElement: HTMLElement = document.querySelector("[data-menu-bar]")!;
const menuBarInner = menuBarElement.querySelector("[data-menu-inner]")!;
const menuBarBlur = menuBarElement.querySelector("[data-menu-blur]")!;
const menuBarSoundControls = menuBarElement.querySelector("[data-sound-controls]");
const menuBarContent = menuBarElement.querySelector("[data-menu-content]")!;
const menuBarNav = menuBarElement.querySelector("[data-menu-nav]")!;
const menuBarDesktopCloseBtn = menuBarContent.querySelector("[data-menu-close-desktop]");
const menuToggleElements = menuBarElement.querySelectorAll("[data-menu-toggle]");

const menuSectionIndicator = <HTMLElement>(
    menuBarElement.querySelector("[data-menu-section-indicator]")
);
const menuNavElements = menuBarElement.querySelectorAll("[data-menu-nav] a");

let menuTL: any;
let mm = gsap.matchMedia(),
    breakPoint = 1024;

mm.add(
    {
        isDesktop: `(min-width: ${breakPoint}px)`,
        isMobile: `(max-width: ${breakPoint - 1}px)`,
    },
    (context) => {
        const { isDesktop } = context.conditions!;

        // Open / close animation of menu
        const menuHeight = isDesktop ? 569 : 613;
        const paddingBottom = isDesktop ? 32 : 14;
        const paddingRight = isDesktop ? 32 : 16;
        const bottom = isDesktop ? -32 : -8;
        const duration = isDesktop ? 0.4 : 0.25;
        const borderRadiusDuration = isDesktop ? 0.2 : 0.175; // half of total duration

        // gsap - set default for menu opening animation
        gsap.defaults({
            ease: "power2.inOut",
            duration: duration,
        });

        menuTL = gsap
            .timeline({
                paused: true,
                onComplete() {
                    // Make sure menu is in view when opening (only relevant if down by footer, unsticked)
                    if (menuBarElement.offsetTop) {
                        gsap.to(window, { duration: 0.3, scrollTo: menuBarElement.offsetTop });
                    }

                    menuBarNav.classList.add("pointer-events-auto");
                },
                onReverseComplete() {
                    gsap.set(menuBarInner, { clearProps: "all" });
                    gsap.set(menuBarContent, { clearProps: "all" });
                    gsap.set(menuBarSoundControls, { clearProps: "all" });
                    menuTL.invalidate(); // Make sure values refreshes if page is resized
                    menuBarNav.classList.remove("pointer-events-auto");
                },
            })
            .to(
                menuBarInner,
                {
                    width: "100%",
                    height: menuHeight,
                    bottom: bottom,
                    paddingBottom: paddingBottom,
                    paddingRight: paddingRight,
                },
                0
            )
            .to(
                menuBarInner,
                {
                    duration: borderRadiusDuration,
                    borderRadius: "0px",
                },
                0
            )
            .to(
                menuBarBlur,
                {
                    autoAlpha: 1,
                },
                0
            )
            .to(
                menuBarContent,
                {
                    width: "100%",
                    height: menuHeight,
                },
                0
            )
            .to(
                menuBarNav,
                {
                    autoAlpha: 1,
                },
                0
            )
            .to(
                menuBarDesktopCloseBtn,
                {
                    autoAlpha: 1,
                },
                0
            )
            .to(
                menuBarSoundControls,
                {
                    autoAlpha: 1,
                    delay: 0.1,
                },
                0
            )
            .reverse();

        return () => {
            // If menu is open and window is resized, close and reset IF changes breakpoint (mobile / desktop)
            if (menuBarElement.classList.contains(MENU_ACTIVE_CLASS)) {
                toggleMenuVisibility(menuBarElement);
            }
        };
    }
);

// Function to toggle menu visibility / active state and animate in content
function toggleMenuVisibility(element: Element): void {
    element.classList.toggle(MENU_ACTIVE_CLASS);
    menuTL.reversed(!menuTL.reversed());
    toggleScrollLock();
}

// Setup click eventlisteners for menu toggles (open and close)
menuToggleElements.forEach((element) => {
    element?.addEventListener("click", () => toggleMenuVisibility(menuBarElement));
});

// setup click eventlistenres for menu nav elements
menuNavElements.forEach((element) => {
    element?.addEventListener("click", (e: any) => {
        e.preventDefault();
        const sectionName = e.target.querySelector("span").innerHTML.trim();

        // scroll to section
        gsap.to(window, {
            duration: 0.8,
            scrollTo: sectionName === "Home" ? 0 : `[data-section-title="${sectionName}"]`,
            onComplete() {
                toggleMenuVisibility(menuBarElement); // close menu
            },
        });
    });
});

// Detect which section is active - for updating the menu indicator
sections.forEach((section) => {
    ScrollTrigger.create({
        trigger: section,
        start: "top center",
        end: "bottom center",
        onToggle: (self) => {
            if (self.isActive) {
                const activeSectionName = self.trigger?.getAttribute("data-section-title") || "";
                menuSectionIndicator.innerHTML = activeSectionName;
            }
        },
    });
});

// Update scroll progress bar(s) on scroll
gsap.to("[data-scroll-progress]", {
    width: "100%",
    ease: "none",
    scrollTrigger: { scrub: true, endTrigger: "[data-page-footer]", end: "top bottom" },
});
