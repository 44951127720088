import KeenSlider, { type KeenSliderInstance, type KeenSliderOptions } from "keen-slider";
import { gsap } from "gsap";

export class Carousel {
    dom = {};

    slider: undefined | KeenSliderInstance;

    constructor(
        sliderElement: HTMLElement,
        paginationElement: HTMLElement,
        carouselOptions: KeenSliderOptions = {}
    ) {
        const paginationContainer = paginationElement.querySelector(
            ".pagination__button-container"
        );

        const paginationButtonPrev = paginationElement.querySelector(".pagination__button-prev");
        const paginationButtonNext = paginationElement.querySelector(".pagination__button-next");

        const allPaginationButtons: HTMLElement[] = [];

        if (sliderElement) {
            this.slider = new KeenSlider(sliderElement, {
                loop: false,
                mode: "snap",
                rtl: false,
                initial: 0,
                slides: { perView: "auto", spacing: 16 },
                breakpoints: {
                    "(min-width: 1024px)": {
                        slides: { perView: "auto", spacing: 24 },
                    },
                },
                ...carouselOptions,
            });

            let currentPaginationButtonIndex = 0;

            this.slider.track.details.slides.forEach((slide: any, idx: number) => {
                if (this.slider) {
                    if (this.slider.track.details.maxIdx >= idx) {
                        const paginationButton = this.createElement(
                            "button",
                            "pagination__button h-2 w-2 rounded-full bg-current",
                            "Pagination item"
                        );

                        if (slide.abs === 0) {
                            gsap.set(paginationButton, { width: "48px" });
                        }

                        allPaginationButtons.push(paginationButton);

                        paginationButton.addEventListener("click", () => this.moveToSlide(idx));
                        paginationContainer?.appendChild(paginationButton);
                    }
                }
            });

            paginationButtonPrev?.addEventListener("click", () => {
                this.slider?.prev();
            });

            paginationButtonNext?.addEventListener("click", () => {
                this.slider?.next();
            });

            this.slider.on("slideChanged", (props: any) => {
                const currentIndex = props.track.details.abs;

                const prevActivePaginationButton =
                    allPaginationButtons[currentPaginationButtonIndex];
                gsap.to(prevActivePaginationButton, { duration: 0.25, width: "8px" });

                const nextActivePaginationButton = allPaginationButtons[currentIndex];
                gsap.to(nextActivePaginationButton, { duration: 0.25, width: "48px" });
                currentPaginationButtonIndex = currentIndex;
            });
        }
    }

    createElement(element: string, className: string, label: string) {
        const htmlElement = document.createElement(element);
        const classNames = className.split(" ");
        classNames.forEach((name) => htmlElement.classList.add(name));
        htmlElement.setAttribute("aria-label", label);
        return htmlElement;
    }

    moveToSlide(slideIndex: number) {
        this.slider?.moveToIdx(slideIndex);
    }
}
