import { gsap } from "gsap";
import { Scramble } from "@helpers/scramble-in-text";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const moduleQuote = document.querySelector("[data-module-quote]")!;
const quotee: HTMLElement = moduleQuote.querySelector("[data-module-quote-quotee]")!;

const scramble = new Scramble(quotee);

ScrollTrigger.create({
    trigger: quotee,
    start: "bottom 90%",
    once: true,
    onEnter() {
        scramble.play();
    },
});
