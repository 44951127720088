import { gsap } from "gsap";

const soundController = document.querySelector("[data-sound-controls]")!;
const toggleSoundButton = document.querySelector("[data-toggle-sound]");
const SOUND_MUTED_CLASS = "sound-muted";

// Equalizer animation timeline
const TL = gsap
    .timeline({ paused: true, repeatRefresh: true, repeat: -1 })
    .to("[data-equalizer-bar]", {
        duration: () => gsap.utils.random(0.4, 0.4),
        scaleY: () => gsap.utils.random(0.3, 1),
        ease: "none",
    });

// Toggle sound button click eventlistener
toggleSoundButton?.addEventListener("click", () => {
    // Toogle all video sounds in hero
    const hero = document.querySelector("[data-module-hero]")!;
    const heroVideos = hero.querySelectorAll("video") || null;

    heroVideos.forEach((video: HTMLVideoElement) => {
        video.muted = !video.muted;
    });

    // Toggle class
    soundController.classList.toggle(SOUND_MUTED_CLASS);

    // Play/pause equalizer animation timeline
    TL.paused(!TL.paused());

    // Clear props from elements, goes to muted state
    if (TL.paused()) {
        gsap.to("[data-equalizer-bar]", {
            duration: 0.4,
            scaleY: 1,
            ease: "none",
            clearProps: "scaleY",
        });
    }
});
