// Setup lenis (https://github.com/dakroomengineering/lenis)
// Make sure it works with gsap ScrollTrigger

import Lenis from "@studio-freight/lenis";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

let lenisInstance: any;

function initLenis() {
    gsap.registerPlugin(ScrollTrigger);

    lenisInstance = new Lenis();

    lenisInstance.on("scroll", ScrollTrigger.update);

    gsap.ticker.add((time) => {
        lenisInstance.raf(time * 1000);
    });

    gsap.ticker.lagSmoothing(0);

    // Forces scrolling to be done on the JavaScript thread, ensuring screen updates are synchronized and the address bar doesn't show/hide on [most] mobile devices.
    // Touch only!
    ScrollTrigger.normalizeScroll({
        type: "touch",
    });

    window.addEventListener("resize", () => {
        ScrollTrigger.refresh();
    });
}

export function getLenisInstance() {
    return lenisInstance;
}

initLenis();
